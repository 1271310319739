<template>
  <div class="progress" :class="'progress--' + ptype">
    <!-- 条形进度条 -->
    <div class="progress-bar" v-if="ptype === 'line'">
      <div class="progress-bar__outer" :style="{ height: strokeHeight + 'px' }">
        <div class="progress-bar__inner" :style="barStyle">
          <!-- 进度条内显示百分比 -->
          <span class="after"></span> 
          <div v-if="textInside" class="progress__text" style="color: white">
            {{ percentage }} 票 
          </div>
        </div>
      </div>
    </div>
    <div v-if="!textInside"  class="progress__text"  :style="{ fontSize: progressTextSize + 'px' }" >
      <span v-if="!status"> 已淘汰的号码 <label>{{ percentage}}</label>注</span>
      <span class="last" v-if="!status"> 剩余号码<label>{{ totalNum - percentage}}</label>注</span>
    </div>
  </div>
</template>
<script>
import maiyuanRequest from "../../units/maiyaunRuquest.js";
import { getActivity } from "../../units/activity.js";
import {getToken} from "@/units/token";
 
export default {
  name: "realTimeProgress",
  data() {
    return {
      strokeHeight: 16,
      percentage: "",  //已淘汰的号码
      totalNum: "",//总票数
      tiptext:"",
      status: null,
      ptype:"line",
      textInside: false,
      pcolor: "",
      cwidth: 126,
      timeorder:"", 
    }
  },
  computed: {
    progressTextSize() {
      return 9 + this.strokeHeight * 0.4;
    },
    stroke() {
      let color;
      if (this.pcolor) {
        return this.pcolor;
      }
      switch (this.status) {
        case "success":
          color = "#13ce66";
          break;
        case "failure":
          color = "#ff4949";
          break;
        default:
          color = "#339aff";
          break;
      }
      return color;
    },
    barStyle() {
      let total = (this.percentage / this.totalNum) * 100;
      if(this.percentage==0 && this.totalNum == 0){
        total = 0;
      } 
      // 计算属性调用其他计算属性，必须加this关键字，否则找不到
      return { width: total + '%', backgroundColor: this.stroke };
    },
  },
  methods:{
    updateProgressValue(newValue){
      this.$emit('progress_updated', newValue);
    },
    PersonalBetdetails(){
      let that = this;  
       let activityInfo = getActivity() ? JSON.parse(getActivity()) : null;
        let activtiyId = activityInfo.activityId || null;
        let activityRoundId = activityInfo.activityRoundId || null;
        let apiAuth = getToken();
        console.log(this.variable)
      // console.log("jennyjennyjennyjennyjennyjennyjennyjennyjennyjennyjennyjennyjennyjenny",this.timeorder)
      if(this.variable==1){
        maiyuanRequest({
          method: "get",
          url: "real_time_order_num",
          headers: { "Authori-zation": "Bearer "+apiAuth},
          params: {activtiy_id:activtiyId, activity_round_id: activityRoundId,num:this.timeorder},
        }).then((res) => {
          let response = res.data;
          if(response.status==400){
            return
          }
          that.totalNum = response.data.betting_record_count;
          that.tiptext=  response.data.surplus_count_text;
          that.percentage = response.data.eliminate_count;
          this.updateProgressValue(that.tiptext)
          // console.log("response.data.company",response.data )
        });
      }else{
        maiyuanRequest({
          method: "get",
          url: "real_time_order_num",
          params: {activtiy_id:activtiyId, activity_round_id: activityRoundId,num:this.timeorder},
        }).then((res) => {
          let response = res.data;
          if(response.status==400){
            return
          }
          that.totalNum = response.data.betting_record_count;
          that.percentage = response.data.eliminate_count;
          that.tiptext=  response.data.surplus_count_text;
          this.updateProgressValue(that.tiptext)
          // console.log("response.data.company",response.data )
        });
      }
    },
  },
  props: ["variable"],
  mounted(){
    this.$bus.$on("suiji",(val)=>{
            this.timeorder= val 
            console.log("实时订单数量",val)// suiji 是方法名字 定义 接收 兄弟传的数据 timeorder自己定义的
            this.PersonalBetdetails()
    }) 
    
  }
};
</script>
<style lang="less"> 
    .syj-timeordernum{
    width: 50%;
    margin: auto; 
.real-num{   
  span{background: #fff;
    margin: 0 10px;    height: 100px;
    text-align: center;
    display: inline-block;
    justify-content: center;
    width: 73px;
    border-radius: 10px;
  } height: 100px;
    font-size: 70px;    overflow: hidden;
    letter-spacing: 1px; 
    margin: auto;
    font-weight: 600;
    line-height:  100px;
    color: #f14b38;
    display: inline-block;}
.progress {
    display: flex;
    flex-direction: column;    height: 65px;
    justify-content: space-around;
    margin:35px 0;
    text-align: left;
}
.progress-bar {
  display: inline-block;
  width: 100%;
  box-sizing: border-box; /* 盒模型的方式 */ 
}
.progress-bar__outer {
  width: 100%;
  border-radius: 10px;
    background-color: #ffffff;
}
.progress-bar__inner {
  position: relative;
    position: relative;
    background-image: linear-gradient(90deg, #fb7157 0%, #f8325a 100%);
    background-image: -ms-linear-gradient(90deg, #fb7157 0%, #f8325a 100%); 
  background-image: -webkit-linear-gradient(90deg, #fb7157 0%, #f8325a 100%); ;
  background-image: -ms-linear-gradient(90deg, #fb7157 0%, #f8325a 100%); ;
  border-radius: 10px;
  height: 100%;
  transition: width 0.6s ease;
  text-align: right;
  line-height: 80%;
  span.after {
    &::after {
        position: absolute;
    width: 16px;
    height: 16px;
    background: #fff;
    content: "";
    top: 53%;
    left: 39%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    }
 
    position: absolute;
    width: 45px;
    height: 45px;
    background: linear-gradient(90deg, #f83c59 0%, #f71c5b 100%);
    top: -18px;
    right: -36px;
    clip-path: inset(6px 10px 3px 0px round 44px);
  }
}
.progress__text { 
    display: flex;
    justify-content: space-between;
    color: #fff;
    letter-spacing: 1px;
    margin-top: 5px;
    label{color: #dd0e19;}
    span.last{
    font-weight: lighter;
}
}

.progress--circle {
  display: inline-block;
  position: relative;
}

.progress--circle .progress__text {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  margin-left: 0px;
  text-align: center;
  width: 100%;
}}
</style>