<template>
  <div class="syj_realTtimeorder">
    <div class="amz-preview-content">
      <ul id="amz-timezone" data-opacity="2">
        <li data-v-418dce2f="" class="amz-timezone-item" data-summer="false"><img data-v-418dce2f=""
                                                                                  src="https://maiyuan-zhusun.oss-cn-hangzhou.aliyuncs.com/upload/bj.png"
                                                                                  data-raw-src="https://img.amz123.com/upload/index_icon/20220826/bj.png"><span
            data-v-418dce2f="">&nbsp;&nbsp;中国&nbsp;</span><span data-v-418dce2f="" data-tz-offset="0"
                                                                  data-opacity="2"></span>
        </li>
        <li data-v-418dce2f="" class="amz-timezone-item" data-summer="false"><img data-v-418dce2f=""
                                                                                  src="https://maiyuan-zhusun.oss-cn-hangzhou.aliyuncs.com/upload/md.png"
                                                                                  data-raw-src="https://img.amz123.com/upload/index_icon/20220826/md.png"><span
            data-v-418dce2f="">&nbsp;&nbsp;美东&nbsp;</span><span data-v-418dce2f=""
                                                                  data-tz-offset="-13" data-opacity="2"></span>
        </li>
        <li data-v-418dce2f="" class="amz-timezone-item" data-summer="false"><img data-v-418dce2f=""
                                                                                  src="https://maiyuan-zhusun.oss-cn-hangzhou.aliyuncs.com/upload/md.png"
                                                                                  data-raw-src="https://img.amz123.com/upload/index_icon/20220826/md.png"><span
            data-v-418dce2f="">&nbsp;&nbsp;美西&nbsp;</span><span data-v-418dce2f=""
                                                                  data-tz-offset="-16" data-opacity="2"></span>
        </li>
        <li data-v-418dce2f="" class="amz-timezone-item" data-summer="false"><img data-v-418dce2f=""
                                                                                  src="https://maiyuan-zhusun.oss-cn-hangzhou.aliyuncs.com/upload/yg.png"
                                                                                  data-raw-src="https://img.amz123.com/upload/index_icon/20220826/yg.png"><span
            data-v-418dce2f="">&nbsp;&nbsp;英国&nbsp;</span><span data-v-418dce2f="" data-tz-offset="-8"
                                                                  data-opacity="2"></span>
        </li>
        <li data-v-418dce2f="" class="amz-timezone-item" data-summer="false"><img data-v-418dce2f=""
                                                                                  src="https://maiyuan-zhusun.oss-cn-hangzhou.aliyuncs.com/upload/oz.png"
                                                                                  data-raw-src="https://img.amz123.com/upload/index_icon/20220826/oz.png"><span
            data-v-418dce2f="">&nbsp;&nbsp;欧洲&nbsp;</span><span data-v-418dce2f="" data-tz-offset="-7"
                                                                  data-opacity="2"></span>
        </li>
        <li data-v-418dce2f="" class="amz-timezone-item" data-summer="false"><img data-v-418dce2f=""
                                                                                  src="https://maiyuan-zhusun.oss-cn-hangzhou.aliyuncs.com/upload/rb.png"
                                                                                  data-raw-src="https://img.amz123.com/upload/index_icon/20220826/rb.png"><span
            data-v-418dce2f="">&nbsp;&nbsp;日本&nbsp;</span><span data-v-418dce2f="" data-tz-offset="1"
                                                                  data-opacity="2"></span>
        </li>
      </ul>
    </div>
    <div class="syj_page-width">
      <div class="syj-commontitle">
        <title class="commom-title"><span>实时订单数</span></title>
      </div>
      <div class="syj-timeordernum">
        <p class="real-num">
          <!-- <span
            v-for="(timenum, key) in timetotalorder.toString().split('')"
            :key="key">
            {{ timenum }}
          </span> -->
          <span v-for="(item, index) in jackpotamount" :key="index">
        <div v-for="(v, indexs) in 10" :key="indexs" class="item" :style="{ ...style(item) }">
          {{ indexs }}
        </div>
        </span>
        
        </p>
        <realtimeProgress-info :variable="select_all_info1"
                               @progress_updated="progress_updated1"></realtimeProgress-info>
      </div>
      
      <div class="syj_page-btn" v-if="1 == 6">
        <button @click="onOpenbet">
          <img src="https://maiyuan-zhusun.oss-cn-hangzhou.aliyuncs.com/upload/lijitouzhu.png"/>
        </button>
      </div>
      <!-- 投注 弹出层相关内容 start -->
      <div v-if="this.bets_lenght == 6">
        <Betpopsix-info @IsClosebetlist="IsClosebet" v-if="onOpenBetinfo">
        </Betpopsix-info>
      </div>
      <!-- 投注 弹出层相关内容 start -->
      <div v-else>
        <Betpop-info @IsClosebetlist="IsClosebet" v-if="onOpenBetinfo"></Betpop-info>
      </div>
      
      <div class="list-head">
        <div class="switch-con">
          <div class="switch-label">
            <CSwitch
                v-model="select_all_info1"
                @change="tab11"
                active-color="#dd0e19"
                inactive-color="#dd0e19"
                :active-value="1"
                :inactive-value="0"
            />
            <div class="content">
              <div :class="{ on: select_all_info1 === 0 }">全部</div>
              /
              <div :class="{ on: select_all_info1 === 1 }">本人</div>
            </div>
          </div>
        </div>
        
        <div class="time_content">{{ time_content }}</div>
      </div>
      
      <ul class="time-record">
        <li class="listfrist">
            <span v-for="(realtitle, index) in realtitlelist" :key="index">
              {{ realtitle.name }}
            </span>
          <div class="relast cursorPointer" :class="{'is-loading': isloading}" @click="refreshOrder">
            <div>刷新</div>
            <img src="../../assets/images/timeorderrefresh.png">
          </div>
          <!--          <div class="relast">-->
          <!--            <div class="jennyloading" v-show="isloading">-->
          <!--              <svg viewBox="25 25 50 50" class="circular">-->
          <!--                <circle cx="50" cy="50" r="20" fill="none" class="path"></circle>-->
          <!--              </svg>-->
          <!--            </div>-->
          <!--          </div>-->
        
        </li>
        <li v-for="(realtimeorder, index) in realtimeorderlist" :key="index"
            :class="realtimeorder.eliminate_status == 1 ? 'gray' : 'active'">
          <span>{{ realtimeorder.company }}</span>
          <span>{{ realtimeorder.parent_department }}</span>
          <span class="srcname"><img :src="realtimeorder.avatar_url">{{ realtimeorder.name }}</span>
          <span>{{ realtimeorder.type }}</span>
          <span>{{ realtimeorder.award_number }}</span>
          <span>{{ realtimeorder.order_commitment }}</span>
        </li>
        
        <li class="last">
          <button v-if="!issOpen" @click="isOpen">查看更多></button>
          <button v-else>我是有底线的...</button>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
import realtimeProgress from "@/components/realTtimeorder/realTimeProgress";
import maiyuanRequest from "../../units/maiyaunRuquest.js";
//   import request from "../../units/request.js"; //大车调取实时订单数量
import {getActivity} from "../../units/activity.js";
import {getToken} from "@/units/token";
import {currentActivity} from "@/units/api";
import Betpop from "@/components/BetPop";
import Betpopsix from "@/components/BetPopsix";

import CSwitch from '@/components/core/Switch'

export default {
  name: "realTimeorder",
  //   inject: ["reload"],
  
  data() {
    return {
      onOpenBetinfo: false,
      bets_lenght: null,
      time_content: '',
      select_all_info1: 0,//本人还是全部状态
      isloading: false,
      currentPage: 2, //当前页数 ，默认为1
      pageSize: "", // 每页显示数量
      issOpen: false, // 是否展开全部信息的标识 Boolean 默认false
      guess_time_end: null,
      guess_time_start: null,
      delayed: true, //从零变化
      time: 2000, //过度时间
      timing: "ease", //过度动画速度
      spinning: true,
      num: 0,
      number: 0, //数字
      quantity: 4, //默认9个
      numtotal: "",
      
      intervalId: null,
      props: [],
      
      
      realtitlelist: [
        {name: "公司"},
        {name: "部门"},
        {name: "姓名"},
        {name: "类别"},
        {name: "投注号码"},
        {name: "下单承诺"},
      ],
      realtimeorderlist: [],
      pushlist: [],
      pStartTime: null,
      pEndTime: null,
      pTimeorderNum: null,
      timeorder: null
    };
  },
  components: {
    "realtimeProgress-info": realtimeProgress,
    "Betpop-info": Betpop,
    "Betpopsix-info": Betpopsix,
    CSwitch,
  },
  
  //金额滚动数字效果
  computed: {
    // 奖池总金额
    numArr() {
      if (this.num) {
        return (this.num + "").split("");
      } else {
        return new Array(this.number.length).fill(0);
      }
      
    },
    jackpotamount() {
      let arr = [];
      let len = this.numArr.length;
      if (this.quantity && this.quantity > len) {
        arr = [...new Array(this.quantity - len).fill(0), ...this.numArr];
      } else {
        arr = this.numArr;
      }
      return arr;
    },
    //  奖池总金额 end
  },
  // props: {
  //     starttime: {
  //      type: [Number, String],
  //     default:""
  //     },
  //     endtime:{
  //       type: [Number, String],
  //     default:""
  //     },
  //     timeorderNum:{ type: [Number, String],
  //     default:""}
  // },
  props: ["starttime", "endtime", "timeorderNum"],
  methods: {
    IsClosebet() {
      this.onOpenBetinfo = false;
    },
    onOpenbet() {
      this.liveactive();
    },
    liveactive() {
      let that = this;
      currentActivity().then((res) => {
        let response = res.data.data;
        let nowTime = new Date();
        let betting_end_time = new Date(
            response.activity_round.betting_end_time
        );
        let betting_start_time = new Date(
            response.activity_round.betting_start_time
        );
        // console.log(betting_end_time.getTime() , nowTime.getTime())//转换 时间戳比较用
        if (
            betting_end_time.getTime() < nowTime.getTime() ||
            nowTime.getTime() < betting_start_time.getTime()
        ) {
          that.$toast.show("投注已截止咯～", 10000);
          // console.log("jennnyyyyyyyy", res.data.ing);
          return;
        } else {
          this.onOpenBetinfo = true;
          that.bets_lenght = response.activity_round.bets_lenght;
        }
      });
    },
    progress_updated1: function (newvalue) {
      this.time_content = newvalue;
    },
    tab11: function () {
      if (!this.isloading) {
        // if (this.select_all_info1 == 0) {
        //   this.select_all_info1 = 1;
        // } else {
        //   this.select_all_info1 = 0
        // }
        this.PersonalBetdetailsone();
        this.gettimeorder(); //加载数据函数
      } else {
        console.log('不能点');
        return;
      }
    },
    // 滚动数字样式
    style(e) {
      return {
        transform: `translateY(-${e * 100}%)`,
        transition: this.time + "ms",
        transitionTimingFunction: this.timing,
      };
    },
    
    refreshOrder() {
      //   this.reload();
      if (this.isloading) {
        return
      }
      this.PersonalBetdetailsone();
      console.log('jenny111111111')
    },
    // 时时刷新数据
    dataRefreh() {
      // 计时器正在进行中，退出函数
      if (this.intervalId != null) {
        return;
      }
      // 计时器为空，操作
      this.intervalId = setInterval(() => {
        // console.log("刷新" + new Date());
        this.gettimeorder(); //加载数据函数
      }, 5000);
    },
    clear() {
      clearInterval(this.intervalId); //清除计时器
      this.intervalId = null; //设置为null
    },
    // 时时刷新数据 end
    gettimeorder() {
      let that = this;
      if (this.spinning == true) {
        this.spinning = false
        let activityInfo = getActivity() ? JSON.parse(getActivity()) : null;
        let activityRoundId = activityInfo.activityRoundId || null;
        maiyuanRequest({
          method: "post",
          url: "get_guess_count",
          data: {type: "3", date: [this.starttime, this.endtime]},
          params: {
            activity_round_id: activityRoundId
          },
          // headers: { "Access-Token": "qHvOaOLnQekwIABVeBeZKupkNAZrRUzm" },
        }).then((res) => {
          let response = res.data;
          
          // that.number = response.data.num;
          // response.data.num=530000;
          that.numtotal = response.data.num;
          this.$bus.$emit("suiji", response.data.num)//suiji 是方法名字 获取当前数据 往外传的子组件
          console.log("suiji", response.data.num)
          this.spinning = true
        });
      }
    },
    
    PersonalBetdetailsone() {
      let that = this;
      let activityInfo = getActivity() ? JSON.parse(getActivity()) : null;
      let activtiyId = activityInfo.activityId || null;
      let apiAuth = getToken()
      let activityRoundId = activityInfo.activityRoundId || null;
      that.isloading = true
      console.log(that.select_all_info1)
      if (that.select_all_info1 == 1) {
        maiyuanRequest({
          method: "get",
          url: "betting_details",
          headers: {"Authori-zation": "Bearer " + apiAuth},
          params: {
            limit: 1000,
            activtiy_id: activtiyId,
            activity_round_id: activityRoundId, num: this.timeorder
          },
        }).then((res) => {
          let response = res.data;
          that.isloading = false;
          if (response.status == 400) {
            return
          }
          console.log("response.data.length", response.data.length)
          if (response.data.length > 0) {
            that.realtimeorderlist = response.data
            return
          } else {
            that.realtimeorderlist = response.data
          }
        });
      } else {
        maiyuanRequest({
          method: "get",
          url: "betting_details",
          params: {
            limit: 1000,
            activtiy_id: activtiyId,
            activity_round_id: activityRoundId, num: this.timeorder
          },
        }).then((res) => {
          let response = res.data;
          that.isloading = false;
          if (response.status == 400) {
            return
          }
          console.log("response.data.length", response.data.length)
          if (response.data.length > 0) {
            that.realtimeorderlist = response.data
            return
          }
        });
      }
    },
    PersonalBetdetails() {
      let that = this;
      let activityInfo = getActivity() ? JSON.parse(getActivity()) : null;
      let activtiyId = activityInfo.activityId || null;
      let activityRoundId = activityInfo.activityRoundId || null;
      let apiAuth = getToken();
      if (that.select_all_info1 == 1) {
        maiyuanRequest({
          method: "get",
          url: "betting_details",
          headers: {"Authori-zation": "Bearer " + apiAuth},
          params: {
            page: that.currentPage,
            limit: 1000,
            activtiy_id: activtiyId,
            activity_round_id: activityRoundId, num: this.timeorder
          },
        }).then((res) => {
          let response = res.data;
          if (response.status == 400) {
            return
          }
          if (response.data.length > 0) {
            response.data.forEach((e) => {
              that.realtimeorderlist.push(e);
            });
            that.currentPage++
          } else {
            this.issOpen = !this.issOpen;
          }
        });
      } else {
        maiyuanRequest({
          method: "get",
          url: "betting_details",
          params: {
            page: that.currentPage,
            limit: 1000,
            activtiy_id: activtiyId,
            activity_round_id: activityRoundId, num: this.timeorder
          },
        }).then((res) => {
          let response = res.data;
          if (response.status == 400) {
            return
          }
          if (response.data.length > 0) {
            response.data.forEach((e) => {
              that.realtimeorderlist.push(e);
            });
            that.currentPage++
          } else {
            this.issOpen = !this.issOpen;
          }
        });
      }
    },
    isOpen() {
      this.PersonalBetdetails();
      console.log(this.showrealtimeorderlist);
    },
    init() {
      if (this.timeorderNum > 0) {
        this.numtotal = this.timeorderNum
      } else {
        console.log('starttime', this.starttime)
        this.dataRefreh();
      }
      this.$bus.$on("suiji", (val) => {
        this.timeorder = val
        console.log("投注明细获取实时订单数量", val)
        
      })
      this.PersonalBetdetailsone();
    },
    
    loadScript(src, checkIfLoaded = true) {
      return new Promise((resolve, reject) => {
        if (checkIfLoaded) {
          const existingScript = document.querySelector(`script[src="${src}"]`);
          if (existingScript) {
            resolve(existingScript);
            return;
          }
        }
        
        const script = document.createElement('script');
        script.src = src;
        script.onload = () => resolve();
        script.onerror = (error) => reject(error);
        document.head.appendChild(script);
      });
    }
  },
  mounted() {
    this.$nextTick(async () => {
      
      try {
        await this.loadScript('https://maiyuan-zhusun.oss-cn-hangzhou.aliyuncs.com/upload/alltime_v1.1.0.js');
        window.Zc()
      } catch (error) {
        console.error('脚本加载失败', error);
      }
      this.init()
    });
  },
  // 调取金额滚动数监听 start
  watch: {
    // starttime(starttime){
    //   this.pStartTime = starttime
    
    //   // this.gettimeorder(starttime)
    //   console.log('aaaaaaaaaaaaaa',starttime)
    // },
    // endtime(endtime){
    //   this.pEndTime = endtime
    //   // this.gettimeorder(endtime)
    // },
    timeorderNum(timeorderNum) {
      this.pTimeorderNum = timeorderNum
      // this.gettimeorder(starttime)
      console.log("jenny", this.pTimeorderNum)
    },
    numtotal: {
      handler(newValue) {
        //newValue 新的值，oldValue变化前的值
        this.num = newValue;
      },
    },
  }, // 调取金额滚动数监听 end
  
  
  // 时时刷新数据
  destroyed() {
    // 在页面销毁后，清除计时器
    this.clear();
    window.clearZcInterval()
    this.$bus.$off('suiji')
  },
};
</script>
<style lang="less">
.syj_realTtimeorder {
  
  @-webkit-keyframes loading-rotate {
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg)
    }
  }
  @keyframes loading-rotate {
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg)
    }
  }
  
  .jennyloading .circular {
    height: 20px;
    width: 20px;
    -webkit-animation: loading-rotate 2s linear infinite;
    animation: loading-rotate 2s linear infinite
  }
  
  .jennyloading .path {
    -webkit-animation: loading-dash 1.5s ease-in-out infinite;
    animation: loading-dash 1.5s ease-in-out infinite;
    stroke-dasharray: 90, 150;
    stroke-dashoffset: 0;
    stroke-width: 2;
    stroke: #fff;
    stroke-linecap: round;
  }
  
  padding-bottom: 80px;
  
  .relast {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: 0;
    padding: 0 10px;
    color: #fff;
    
    &.is-loading {
      img {
        animation: rotating 1s linear infinite;
      }
    }
    
    div {
      margin-right: 4px;
    }
  }
  
  .syj-commontitle {
    .refresh {
      cursor: pointer;
      position: absolute;
      right: 32px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 64px;
    }
    
    display: flex;
    padding: 15px 0;
    position: relative;
  }
  
  ul.time-record {
    li {
      &.gray {
        background: #d5d5d5;
        border-bottom: 1px solid #c1c1c1;
      }
      
      &:nth-child(2n-1) {
        background: #363fb5;
        
        &.gray {
          background: #d5d5d5;
          border-bottom: 1px solid #c1c1c1;
        }
      }
      
      font-weight: lighter;
      font-size: 16px;
      
      span {
        &.srcname {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          padding-left: 34px;
          width: 16%;
        }
        
        img {
          width: 35px;
          height: 35px;
          border-radius: 50%;
          margin-right: 10px;
        }
        
        width: calc(100% / 5);
      }
      
      &.listfrist {
        position: relative;
        //border-top: 1px solid;
        color: #fff;
        background: #dd0e19;
        height: 44px;
      }
      
      &.active {
        color: #fff;
      }
      
      height: 50px;
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: space-between;
      text-align: center;
      padding: 0 32px;
      
      &.last {
        text-align: center;
        display: inline-block;
        margin: 1rem 0 0.5rem;
        border: none;
        
        background: transparent;
        
        button {
          cursor: pointer;
          height: 35px;
          color: #fff;
          background: linear-gradient(#363fb5 13%, #363fb5 76%);
          border: none;
          border-radius: 25px;
          font-size: 16px;
          font-weight: 600;
          letter-spacing: 3px;
          padding: 0 40px;
          box-shadow: 1px 3px 6px -1px #552d61;
        }
      }
    }
    
    display: flex;
    flex-direction: column;
    padding: 0 15px;
  }
}

.select_all_info {
  cursor: pointer;
  width: 15%;
  margin-left: 9%;
  margin-bottom: 1%;
  background: url("https://maiyuan-zhusun.oss-cn-hangzhou.aliyuncs.com/upload/20231122/35d59782ec0490253eea70e88cef2d45.png") no-repeat;
  height: 25px; /* 设置元素高度 */
}

.select_self_info {
  cursor: pointer;
  width: 15%;
  margin-left: 9%;
  margin-bottom: 1%;
  background: url("https://maiyuan-zhusun.oss-cn-hangzhou.aliyuncs.com/upload/20231122/9ee10cb2fc635cd80283b52dac48ca5b.png") no-repeat;
  height: 25px; /* 设置元素高度 */
}

.time-record-top-box {

}

.syj_page-btn {
  position: absolute;
  /* bottom: 76px; */
  /* left: 49%; */
  right: -8.5%;
  top: 0.2%;
  z-index: 1;
  transform: translateX(-50%);
  
  button {
    //opacity: 0;
    font-weight: 600;
    letter-spacing: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    width: 234px;
    height: 65px;
    background: transparent;
    border: none;
    border-radius: 25px;
    font-size: 16px;
  }
}

.time_content {
  //margin-left: 30%;
  //width: 65%;
  //margin-top: -3%;
  //margin-bottom: 1%;
  //height: 25px;
  color: #fcf9f9;
  //text-align: right;
}

#amz-timezone {
  justify-content: space-between;
  align-items: center;
  position: relative;
  //border: 1px solid #eee;
  padding: 5px .5px;
  margin-bottom: -12px;
  margin-top: .5px;
  color: #fff;
  font-size: 13px;
  display: flex;
  flex-wrap: wrap
}

@media (max-width: 992px) {
  #amz-timezone {
    display: grid;
    grid-template-columns:repeat(4, 1fr)
  }
}

@media (max-width: 991px) {
  #amz-timezone {
    display: none
  }
}

@media (max-width: 1024px) {
  #amz-timezone {
    white-space: nowrap;
    font-size: 12px
  }
}

#amz-timezone .amz-timezone-item {
  height: 20px;
  display: flex;
  align-items: center;
  //width: 16.2%;
  float: left;
  margin-left: 3.3px
}

#amz-timezone .amz-timezone-item img {
  width: 18px;
  height: 12px;
  vertical-align: bottom
}

.amz-preview-content {
  display: flex;
  justify-content: center;
  gap: 16px
}

@keyframes rotating {
  0% {
    transform: rotate(0deg)
  }
  
  to {
    transform: rotate(1turn)
  }
}
</style>

<style lang="less" scoped>
.list-head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 60px 50px 20px;
}

.switch-con {
  display: flex;
  align-items: center;
  flex-shrink: 0;
  
  .switch-label {
    display: flex;
    align-items: center;
    font-size: 24px;
    font-weight: 700;
    color: #363fb5;
    
    .content {
      display: flex;
      align-items: center;
      margin-left: 10px;
      line-height: 1;
      
      .on {
        color: #fff;
      }
    }
  }
}

.time_content {
  flex-shrink: 0;
}

</style>
