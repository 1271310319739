<template>
  <div class="syj_timeprizepool syj_page-width">
    <ul>
      <li class="listprize0">
        <p><i>[</i>奖池总金额<i>]</i></p>
        <div class="timenum">
          <!-- <span
            v-for="(timenum, key) in jackpot_amount.toString().split('')"
            :key="key"
          >
            {{ timenum }}
          </span> -->
          <span v-for="(item, index) in jackpotamount" :key="index">
            <div v-for="(v, indexs) in 10" :key="indexs" class="item" :style="{ ...style(item) }">
              {{ indexs }}
            </div>
          </span>
        </div>
      </li>
      <li class="listprize1">
        <p><i>[</i>参与人数<i>]</i></p>
        <div class="timenum">
          <!-- <span
            v-for="(timenum, key) in participants_count.toString().split('')"
            :key="key"
          >
            {{ timenum }}
          </span> -->
          <span v-for="(item, index) in jennycount" :key="index">
            <div v-for="(v, indexs) in 10" :key="indexs" class="item" :style="{ ...style(item) }">
              {{ indexs }}
            </div>
          </span>
        </div>
      </li>
      <li>
        <p><i>[</i>{{ itemthreetitle }}<i>]</i></p>
        
        <div class="timenumthree">
          <div class="timenumthree-name">
            <div class="col">
              <span class="title">二等奖</span>
              
              <span class="jenny">
                <div v-for="(item, index) in estimateamountsecond" :key="index">
                  <div v-for="(v, indexs) in 10" :key="indexs" class="item" :style="{ ...style(item) }">
                    {{ indexs }}
                  </div>
                </div>
              </span>
            </div>
            <div class="col">
              <span class="title">一等奖</span>
              
              <span class="jenny">
                <div v-for="(item, index) in estimateamountone" :key="index">
                  <div v-for="(v, indexs) in 10" :key="indexs" class="item" :style="{ ...style(item) }">
                    {{ indexs }}
                  </div>
                </div>
              </span>
            </div>
            <div class="col">
              <span class="title">三等奖</span>
              
              <span class="jenny">
                <div v-for="(item, index) in estimateamountthree" :key="index">
                  <div v-for="(v, indexs) in 10" :key="indexs" class="item" :style="{ ...style(item) }">
                    {{ indexs }}
                  </div>
                </div>
              </span>
            </div>
          
          </div>
          <!-- <span>
            {{estimate_amount_second }}
          </span> -->
          
          
          <!-- <span>
            {{  estimate_amount_one }}
          </span> -->
          
          <!-- <span>
            {{ estimate_amount_third }}
          </span> -->
        </div>
      </li>
    </ul>
  </div>
</template>
<script>
import maiyuanRequest from "../../units/maiyaunRuquest.js";

import {getActivity} from "../../units/activity.js";

export default {
  name: "timeprizepool",
  data() {
    return {
      intervalId: null,
      itemthreetitle: "预估中奖金额",
      
      
      delayed: true, //从零变化
      
      time: 2000, //过度时间
      timing: "ease", //过度动画速度
      
      num: 0,
      number: "", //数字
      quantity: 4, //默认9个
      jackpot_amount: "",
      
      estimatenum: "0",
      estimatenumber: "", //数字
      quantityestimate: "1", //默认4个
      estimate_amount_second: "",
      
      estimatenumone: "0",
      estimatenumberone: "", //数字
      quantityestimateone: "1", //默认4个
      estimate_amount_one: "",
      
      numpeople: 0, //对应的num
      numbertwo: "", //数字
      quantitypeople: 1, //默认3个
      participants_count: "",
      
      estimatenumthree: "0",
      estimatenumberthree: "", //数字
      quantityestimatethree: "1", //默认4个
      estimate_amount_third: "",
    };
  },
  
  //金额滚动数字效果
  computed: {
    // 奖池总金额
    numArr() {
      if (this.num) {
        return (this.num + "").split("");
      } else {
        return new Array(this.number.length).fill(0);
      }
    },
    
    jackpotamount() {
      let arr = [];
      let len = this.numArr.length;
      if (this.quantity && this.quantity > len) {
        arr = [...new Array(this.quantity - len).fill(0), ...this.numArr];
      } else {
        arr = this.numArr;
      }
      return arr;
    },
    //  奖池总金额 end
    
    // 参与人数数据变动效果
    numArrtwo() {
      if (this.numpeople) {
        return (this.numpeople + "").split("");
      } else {
        return new Array(this.numbertwo.length).fill(0);
      }
    },
    
    jennycount() {
      let arr = [];
      let len = this.numArrtwo.length;
      if (this.quantitypeople && this.quantitypeople > len) {
        arr = [
          ...new Array(this.quantitypeople - len).fill(0),
          ...this.numArrtwo,
        ];
      } else {
        arr = this.numArrtwo;
      }
      return arr;
    },
    // 参与人数数据变动效果
    
    //   // 预估中奖金额二等奖
    estimatetwo() {
      if (this.estimatenum) {
        return (this.estimatenum + "").split("");
      } else {
        return new Array(this.estimatenumber.length).fill(0);
      }
    },
    
    estimateamountsecond() {
      let arr = [];
      let len = this.estimatetwo.length;
      if (this.quantityestimate && this.quantityestimate > len) {
        arr = [
          ...new Array(this.quantityestimate - len).fill(0),
          ...this.estimatetwo,
        ];
      } else {
        arr = this.estimatetwo;
      }
      return arr;
    },
    //  // 预估中奖金额二等奖
    // 预估中奖金额一等奖
    estimateone() {
      if (this.estimatenumone) {
        return (this.estimatenumone + "").split("");
      } else {
        return new Array(this.estimatenumberone.length).fill(0);
      }
    },
    
    estimateamountone() {
      let arr = [];
      let len = this.estimateone.length;
      if (this.quantityestimateone && this.quantityestimateone > len) {
        arr = [
          ...new Array(this.quantityestimateone - len).fill(0),
          ...this.estimateone,
        ];
      } else {
        arr = this.estimateone;
      }
      return arr;
    },
    
    // 预估中奖金额三等奖
    estimatethree() {
      if (this.estimatenumthree) {
        return (this.estimatenumthree + "").split("");
      } else {
        return new Array(this.estimatenumberthree.length).fill(0);
      }
    },
    
    estimateamountthree() {
      let arr = [];
      let len = this.estimatethree.length;
      if (this.quantityestimatethree && this.quantityestimatethree > len) {
        arr = [
          ...new Array(this.quantityestimatethree - len).fill(0),
          ...this.estimatethree,
        ];
      } else {
        arr = this.estimatethree;
      }
      return arr;
    },
    //  奖池总金额 end
  },
  //金额滚动数字效果
  methods: {
    // 滚动数字样式
    style(e) {
      return {
        transform: `translateY(-${e * 100}%)`,
        transition: this.time + "ms",
        transitionTimingFunction: this.timing,
      };
    },
    timePrizePool() {
      let that = this;
      let activityInfo = getActivity() ? JSON.parse(getActivity()) : null;
      let activtiyId = activityInfo.activityId || null;
      let activityRoundId = activityInfo.activityRoundId || null;
      maiyuanRequest({
        method: "get",
        url: "real_time_order",
        params: {activtiy_id: activtiyId, activity_round_id: activityRoundId},
      }).then((res) => {
        let response = res.data;
        if (response.status == 400) {
          return
        }
        that.estimate_amount_one = response.data.estimate_amount_one;
        that.estimate_amount_second = response.data.estimate_amount_second;
        that.estimate_amount_third = response.data.estimate_amount_third;
        that.jackpot_amount = response.data.jackpot_amount;
        that.participants_count = response.data.participants_count;
        console.log("timepoollistthree", response.data);
        
        
      });
    },
    
    // 时时刷新数据
    dataRefreh() {
      // 计时器正在进行中，退出函数
      if (this.intervalId != null) {
        return;
      }
      // 计时器为空，操作
      this.intervalId = setInterval(() => {
        console.log("刷新数据" + new Date());
        this.timePrizePool(); //加载数据函数
      }, 100000000);
    },
    clear() {
      clearInterval(this.intervalId); //清除计时器
      this.intervalId = null; //设置为null
    },
    // 时时刷新数据 end
  },
  mounted() {
    this.timePrizePool();
    // this.dataRefreh(); 去掉i刷新时间
    // 调取金额滚动数 start
    if (this.delayed) {
      setTimeout(() => {
        this.num = this.number;
        this.numpeople = this.numbertwo;
        this.estimatenum = this.estimatenumber;
        this.estimatenumone = this.estimatenumberone;
        this.estimatenumthree = this.estimatenumberthree;
      }, 1000);
    } else {
      this.num = this.number;
      this.numpeople = this.numbertwo;
      this.estimatenum = this.estimatenumber;
      this.estimatenumone = this.estimatenumberone;
      this.estimatenumthree = this.estimatenumberthree;
    }
    setTimeout(() => {
      this.number = this.jackpot_amount;
      this.numbertwo = this.participants_count;
      this.estimatenum = this.estimate_amount_second;
      this.estimatenumone = this.estimate_amount_one;
      this.estimatenumthree = this.estimate_amount_third;
    }, 2000);
    // 调取金额滚动数 end
  },
  // 时时刷新数据
  destroyed() {
    // 在页面销毁后，清除计时器
    this.clear();
  },
  // 调取金额滚动数监听 start
  watch: {
    number: {
      handler(newValue, oldValue) {
        //newValue 新的值，oldValue变化前的值
        this.num = newValue;
        console.log(newValue, oldValue);
      },
    },
    numbertwo: {
      handler(peoplenum, oldValue) {
        //peoplenum 新的值，oldValue变化前的值
        this.numpeople = peoplenum;
        
        console.log(peoplenum, oldValue);
      },
    },
    estimatenumber: {
      handler(estimatenum, oldValue) {
        //peoplenum 新的值，oldValue变化前的值
        this.estimatenum = estimatenum;
        console.log(estimatenum, oldValue);
      },
    },
    estimatenumberone: {
      handler(estimatenumone, oldValue) {
        //peoplenum 新的值，oldValue变化前的值
        this.estimatenumone = estimatenumone;
        console.log("peoplenum", estimatenumone, oldValue);
      },
    },
    estimatenumberthree: {
      handler(estimatenumthree, oldValue) {
        //peoplenum 新的值，oldValue变化前的值
        this.estimatenumthree = estimatenumthree;
        console.log("peoplenum", estimatenumthree, oldValue);
      },
    },
  }, // 调取金额滚动数监听 end
};
</script>
<style lang="less">
.syj_timeprizepool {
  padding: 20px 0 20px;
  
  ul {
    display: flex;
    justify-content: space-between;
    
    li {
      p {
        i {
          color: #ffce29;
          font-style: inherit;
        }
        
        margin: 10px 0 0;
        font-size: 22px;
        color: #ffce29;
        font-weight: 600;
        letter-spacing: 1px;
      }
      
      .timenum {
        font-size: 48px;
        color: #0a0a0a;
        display: flex;
        align-items: center;
        width: 100%;
        height: 65px;
        overflow: hidden;
        justify-content: center;
        
        span {
          // background: url(../../assets/images/numbbg.png) no-repeat top center;
          width: 34px;
          background-size: contain;
          height: 54px;
          color: #f04b38;
          margin: 0 0px;
          font-weight: 600;
          line-height: 54px;
          
          div {
            display: table;
            color: transparent;
            /* 创建线性渐变背景 */
            background-image: linear-gradient(140deg, #ffce2a, #ffce2a, #ffce2a, #ffce2a, white, white, white);
            /* 裁剪背景至文字 */
            background-clip: text;
            /* 保证文字背景在各个浏览器中正确渲染 */
            -webkit-background-clip: text;
            /* 保证文字颜色在WebKit浏览器中透明 */
            -webkit-text-fill-color: transparent;
            /* 设置字体大小和字体 */
            font-size: 52px;
            font-weight: 600;
          }
        }
      }
      
      .timenumthree {
        position: relative;
        
        .timenumthree-name {
          display: flex;
          position: absolute;
          top: -5px;
          left: 50%;
          transform: translateX(-50%);
          width: 76%;
          justify-content: space-around;
          
          span {
            font-size: 14px;
            height: 20px;
            color: #ffd933;
            font-weight: 600;
          }
          
          .col {
            display: flex;
            flex-direction: column;
            align-items: center;
          }
        }
        
        .title {
          margin: 10px 8px 0;
          display: flex;
          overflow: hidden;
          font-size: 36px;
          height: 44px;
          
          
        }
        
        .jenny {
          margin: -6px 0 0;
          height: auto !important;
          display: flex;
          overflow: hidden;
          font-size: 36px;
          
          div {
            height: 44px;
            overflow: hidden;
            color: transparent;
            background-image: linear-gradient(140deg, #ffce2a, #ffce2a, #ffce2a, #ffce2a, white, white, white);
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            font-size: 38px;
            font-weight: 600;
          }
        }
        
        display: flex;
        justify-content: center;
        align-items: center;
        color: #f04b38;
        font-weight: 600;
        height: 65px;
      }
      
      width: 399px;
      flex-direction: column;
      height: 205px;
      display: flex;
      justify-content: center;
      position: relative;
      //background: url("https://maiyuan-zhusun.oss-cn-hangzhou.aliyuncs.com/upload/timeprize01.png") no-repeat top center/cover;
      background: url("https://maiyuan-zhusun.oss-cn-hangzhou.aliyuncs.com/upload/20231121/4be14852a42ffe5d562fa6d678ddbb17.gif") no-repeat top center/cover;
    }
  }
}
</style>
