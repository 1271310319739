<template>
  <div class="switch-wrap" :class="{ 'is-checked': isChecked }" @click="toggle">
    <input type="checkbox" class="switch-input" :checked="isChecked" @change="toggle" />
    <span class="switch-core" :style="style"></span>
  </div>
</template>

<script>
export default {
  name: "CSwitch",
  props: {
    value: {
      type: [Boolean, String, Number],
      required: true,
    },
    activeColor: {
      type: String,
      default: "#dd0e19",
    },
    inactiveColor: {
      type: String,
      default: "#dcdfe6",
    },
    activeValue: {
      type: [Boolean, String, Number],
      default: () => true,
    },
    inactiveValue: {
      type: [Boolean, String, Number],
      default: () => false,
    }
  },
  data() {
    return {
      style: null,
    };
  },
  computed: {
    isChecked() {
      return this.value === this.activeValue;
    },
  },
  watch: {
    isChecked: {
      handler(val) {
        this.style = val
            ? {
              background: this.activeColor,
              borderColor: this.activeColor,
            }
            : {
              background: this.inactiveColor,
              borderColor: this.inactiveColor,
            };
      },
      immediate: true,
    }
  },
  methods: {
    toggle() {
      const newValue = this.isChecked ? this.inactiveValue : this.activeValue;
      this.$emit("input", newValue);
      this.$emit("change", newValue);
    }
  }
};
</script>

<style scoped lang="less">
.switch-wrap {
  display: inline-flex;
  align-items: center;
  position: relative;
  font-size: 14px;
  line-height: 20px;
  height: 20px;
  vertical-align: middle;
  
  &.is-checked {
    .switch-core {
      &:after {
        left: 100%;
        margin-left: -17px;
      }
    }
  }
  
  .switch-input {
    position: absolute;
    width: 0;
    height: 0;
    opacity: 0;
    margin: 0;
  }
  
  .switch-core {
    margin: 0;
    display: inline-block;
    position: relative;
    width: 40px;
    height: 20px;
    border: 1px solid #dcdfe6;
    outline: none;
    border-radius: 10px;
    box-sizing: border-box;
    background: #dcdfe6;
    cursor: pointer;
    transition: border-color .3s, background-color .3s;
    vertical-align: middle;
    
    &:after {
      content: "";
      position: absolute;
      top: 1px;
      left: 1px;
      border-radius: 100%;
      transition: all .3s;
      width: 16px;
      height: 16px;
      background-color: #fff;
    }
  }
}
</style>
